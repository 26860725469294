<template>
  <div class="app-container">
    <nav>
      <div class="tips">
          <!-- <div class="text">通讯码</div> -->
          <el-input v-model="queryValue" placeholder="请输入企业名称"></el-input>
          <el-button @click="initTable" type="primary" class="btn">查询</el-button>
      </div>
      <div class="operation">
        <el-button @click="add" type="primary" class="btn">+ 新增</el-button>
      </div>
    </nav>

    <div class="insurance_table" v-loading='isLoading'>
     <comps-table :tableData="tableData" :tableHeader="tableHeader"  :total='total'  @current-events="savePage" class="comp_table" v-if="tableData.length" @size-events='saveSize'  >
      </comps-table>
      <nodata v-else message="暂无数据" class="no_data" />
    </div>

    


   <renew-dialog :visible='renewVis' :rowData='rowData' :cancelDialog='cancelDialog' :confirmDialog='confirmDialog'/>


  </div>
</template>

<script>
  // import delDialog from '@/components/Dialog'
  import compsTable from "@/components/table/table";
  import { insurancesList, deleteInsurances } from '@/api/insurances'
  import renewDialog from './dialog/Renew'
  
  export default {
    components: { compsTable,renewDialog },
    data() {
      return {
        isLoading: false,
        hasData: false,
        tableHeader: [
          //通讯码 用户名称 项目编码 证书类型编码 用户证件号 私销保护密码 证书有效期年限证书过期时间 证书状态 证书类型 经办人名称 经办人手机号 创建时间       
          // {prop: "name", label: "通讯码", center: 'center', width:'100',
          //   // render: (h, data) => {
          //   //   return (
          //   //     <span v-if='data.row.name == "01101"'>
          //   //       {data.row.name}
          //   //     </span>
          //   //   );
          //   // },
          // },
          {prop:'companyName',label:'企业名称',center: 'center',width:'140'},
          // {prop:'appCode',label:'项目编码',center: 'center',width:'120'},
          {prop:'idType',label:'证书类型编码',center: 'center',width:'120',
            formatter: (row) => {
              const typeMap = {
                '0': '居民身份证',
                'N': '统一社会信用代码',
              }
              return typeMap[row.idType];
            },
          },
          {prop:'idNo',label:'用户证件号',center: 'center',width:'120'},
          {prop:'caPwd',label:'私钥保护密码',center: 'center',width:'120'},
          {prop:'years',label:'证书有效期年限',center: 'center',width:'130'},
          {prop:'endDate',label:'证书过期时间',center: 'center',width:'160'},
          // status 01-正常 00-已过期
          {prop:'status',label:'证书状态',center: 'center',width:'120',
            render: (h, data) => {
              return (
                <span>
                  {data.row.status=='01'?'正常':'已过期'}
                </span>
              );
            },
          },
          {prop:'caType',label:'证书类型',center: 'center',width:'120',
            formatter: (row) => {
              const typeMap = {
                '1': 'RSA1024',
                '2': 'RSA2048',
                '3': 'SM2',
              }
              return typeMap[row.caType];
            },
          },
          {prop:'operatorName',label:'经办人名称',center: 'center',width:'120'},
          {prop:'operatorPhone',label:'经办人手机号',center: 'center',width:'120'},
          {prop:'createTime',label:'创建时间',center: 'center',width:'160'},
          {
            label: "操作",
            center: 'center',
            // fixed:"right",
            width:'130',
            render: (h, data) => {
              return (
                  <span>
                    <el-button class='btn' type="text" onClick={() => { this.edit(data.row);}}>
                      编辑
                    </el-button>
                    <el-button class='btn' type="text" onClick={() => { this.Renew(data.row);}}>
                      续费
                    </el-button>
                  </span>
                );
            },
          },
        ],
        queryValue:'',
        page: 1,
        tableData: [],
        total: 0,
        pageSize: 10,
        isdeling: false,
        multipleSelection:[],
        
        config: {
          top: '20vh',
          width: '16%',
          title: '提示',
          btnTxt: ["确定", "取消"]
        },
        // 保险id
        id: '',

        
        renewVis:false, //续费
        rowData:{}

      }
    },
    methods: {
      add() {
        // einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
        this.$router.push('CA/add')
      },
      edit(row) {
        this.$router.push({
          path: 'CA/edit',
          query: {
            id: row.id
          }
        })
      },
      detection(row) {
        this.$router.push({
          path: 'customerInfo/edit',
          query: {
            name: row.name,
            companyRatio: row.companyRatio,
            personalRatio: row.personalRatio,
            isDefault: row.isDefault,
            id: row.id
          }
        })
      },

     

      
      //续费
      Renew(row) {
        this.renewVis = true;
        console.log(row,'row12333')
        this.rowData = row
      },
      cancelDialog(){
        this.renewVis = false;
      },
     

      confirmDialog(data) {
        console.log(data,'rowDatarowDatarowData')
        this.isdeling = true
         this.$store.dispatch("renewCA", {
          ...data,userId: this.getToken('userId'),
        }).then(res => {
          if (res.success) {
             einvAlert.success("提示",res.msg)
            this.renewVis = false;
            this.initTable()
          } else {
            einvAlert.error("提示",res.msg)
             this.renewVis = false;
          }
          this.isdeling = false
        }).catch(err => {
          this.isdeling = false
        })
        // let a = '未过期的证书不允许续费'
        // // if(a = '成功'){
        // //     // einvAlerteinvAlert.success("导入成功","导入成功"+res.data.successCount+"张");
        // //     einvAlert.success("提交成功");
        // // }
        // if(a = '不允许重复提交'){
        //     // einvAlert.warning('提示',res.msg,'确定','我知道了')
        //     einvAlert.warning('提示','续费申请已提交,请勿重复提交','确定','我知道了')
        // }
        // if(a = '未过期的证书不允许续费'){
        //      einvAlert.warning('提示','当前证书尚未过期,无需进行续费','确定','我知道了')
        // }
      },
      savePage(val) {
        this.pageSize = val
        this.initTable()
      },
      saveSize(val) {
        this.pageSize = val
        this.initTable()
      },
      
      // CA列表
      initTable() {
        this.isLoading = true;
        this.$store.dispatch("findCA", {
          companyName: this.queryValue,
          page: this.page,
          rows: this.pageSize,
          userId: this.getToken('userId'),
          // companyId : this.getToken('companyId'),
          // companyName: this.getToken('nowCompany'),
        }).then(res => {
          if (res.success) {
            this.isLoading = false;
            this.tableData = res.data;
            this.total = res.data.length;
          } else {
            this.isLoading = false;
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          einvAlert.error("提示",err)
          this.isLoading = false;
        })
      },

      imports(){
        this.importVis = true;
      },
      importCancel(){
        this.importVis = false;
      },
     handleExport(){
        this.exportVis = true;
     },
      exportCancel(){
        this.exportVis = false;
      },
     
      tipsCancel(){
        this.promptVisible = false;
      }
    },
    mounted() {
      this.initTable()
    }
  }
</script>

<style lang="less">
  nav{
    .text{
      font-size: 14px;
      width: 110px;
    }
    .tips{
      display: flex;
      align-items: center;
    }
    .btn{
      margin-left: 10px;
    }
  }
  .btn{
    margin-right: 10px;
  }
  .insurance_table {
    height: calc(100% - 35px);
    padding: 24px 20px;
    background-color: #fff;
    .comp_table {
      // border: 1px solid #ebeef5;
      width: 100%;
    }
  }
  // .answer {
  //   text-align: center;
  //   padding: 24px 0 14px;
  //   font-size: 16px;
  // }
 /deep/ .el-table__body-wrapper{
  height: 800px !important;
  overflow-y: auto;
}
</style>
